import React, { useState, useEffect, useMemo } from 'react'

import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'
import Tooltip from '@mui/material/Tooltip'
import { useTable, useSortBy, usePagination } from 'react-table'

import { useSelector, useDispatch } from 'react-redux'
import { useListPlans, useLoadPlan } from '../../../hooks/usePlan'
import { resetLoadedPlan } from '../../../redux/ducks/plan'
import { setIsModal, setModalDetailMsg } from '../../../redux/ducks/notification'
import { useAuth } from '../../../auth/authProvider'
import { getAllStripePlans, loadStripePlan } from '../../../utils/api/sigyn'
import { displayDate } from '../../../utils/format'

import AlertModal from '../../Shared/AlertModal'
import CopyButton from '../../Shared/CopyButton/index'
import DetailsModal from '../../Plan/DetailsModal/index'
import Loading from '../../Shared/Loading'
import UnionArrows from '../../Shared/UnionArrows/index'
import ViewButton from '../../Shared/ViewButton'

import style from './style.module.scss'

const PlanTable = () => {
  const { user: authUser } = useAuth()
  const dispatch = useDispatch()
  const [copiedPlan, setCopiedPlan] = useState(null)
  const [requestListPlans, plansResponse] = useListPlans()
  const [requestLoadPlan] = useLoadPlan()

  const { plans: { records, planTotal }, plan } = useSelector((state) => state.plan)
  const {
    modalMessage: { modalDetail },
    apiMessage: { apiError }
  } = useSelector((state) => state.notification)

  useEffect(() => {
    requestListPlans(getAllStripePlans(authUser))
  }, [authUser, requestListPlans])

  useEffect(() => {
    if (apiError) {
      dispatch([setIsModal(true), setModalDetailMsg(apiError)])
    }
  }, [apiError, dispatch])

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        id: 'name',
        accessor: 'attributes.name'
      },
      {
        Header: 'ID',
        id: 'id',
        accessor: 'id'
      },
      {
        Header: 'Created At',
        id: 'createdAt',
        accessor: 'attributes.createdAt',
        Cell: ({ cell: { value } }) => displayDate(new Date(value))
      },
      {
        Header: 'Updated At',
        id: 'updatedAt',
        accessor: 'attributes.updatedAt',
        Cell: ({ cell: { value } }) => displayDate(new Date(value))
      }
    ],
    []
  )

  const {
    getTableBodyProps,
    getTableProps,
    gotoPage,
    headerGroups,
    nextPage,
    page,
    pageOptions,
    prepareRow,
    previousPage,
    state
  } = useTable(
    {
      columns,
      data: records,
      initialState: { pageSize: 15 }
    },
    useSortBy,
    usePagination
  )

  const onCopyClick = (cell) => {
    const { id } = cell.row.original
    navigator.clipboard.writeText(id)
    setCopiedPlan(id)
  }

  const onViewClick = (cell) => {
    const { id: planId } = cell.row.original
    requestLoadPlan(loadStripePlan(authUser, planId))
  }

  const handleDismissModal = () => {
    dispatch(resetLoadedPlan())
    setCopiedPlan(null)
  }

  const getCurrentSortDirection = (column) => {
    if (!column.isSorted) return null
    return column.isSorted && !column.isSortedDesc ? 'asc' : 'desc'
  }

  const getNextSortDirection = (column) => {
    if (!column.isSorted) return 'Toggle Ascending'
    return column.isSorted && !column.isSortedDesc ? 'Toggle Descending' : 'Reset Toggle'
  }

  return (
    <>
      <AlertModal modalMessage={modalDetail} />
      {plansResponse.state === 'loading' && <Loading />}

      <DetailsModal isOpen={!!plan.id} onDismissModal={handleDismissModal} plan={plan} />

      <div className={style.showingDisplay}>
        {!planTotal ? 'No plays to display' : `Showing ${(state.pageIndex * state.pageSize) + 1}
        -${(state.pageIndex * state.pageSize) + page.length} of ${planTotal} Total Plans`}
      </div>

      <table className={style.table} {...getTableProps()}>

        <colgroup>
          <col span="1" />
          <col span="1" />
          <col span="1" />
          <col span="1" />
        </colgroup>

        <thead className={style.tableHead}>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                  className={style.tableHeader}
                >
                  <Tooltip title={getNextSortDirection(column)} arrow>
                    <span>{column.render('Header')}</span>
                  </Tooltip>
                  <UnionArrows sortDirection={getCurrentSortDirection(column)} />
                </th>

              ))}
            </tr>
          ))}
        </thead>

        <tbody className={style.tableBody} {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell, idx) => (
                  <td {...cell.getCellProps()}>

                    <div className={style.dataCellWrapper}>
                      {cell.render('Cell')}

                      {idx === 1
                    && (
                    <CopyButton
                      className={style.copyButton}
                      handleClick={() => onCopyClick(cell)}
                      isSelected={copiedPlan === cell.row.original.id}
                    />
                    )}

                      {idx === 3
                    && (
                    <ViewButton
                      handleClick={() => onViewClick(cell)}
                      isSelected={plan.id === cell.row.original.id}
                    />
                    )}

                    </div>
                  </td>
                ))}
              </tr>
            )
          })}
        </tbody>
      </table>

      <div className={style.pagination}>
        <Pagination>

          <PaginationItem disabled={state.pageIndex === 0}>
            <PaginationLink onClick={previousPage}>Previous</PaginationLink>
          </PaginationItem>

          {pageOptions.map((pageNum) => (
            <PaginationItem key={pageNum} active={state.pageIndex === pageNum}>
              <PaginationLink onClick={() => gotoPage(pageNum)}>{pageNum + 1}</PaginationLink>
            </PaginationItem>
          ))}

          <PaginationItem disabled={state.pageIndex === pageOptions.length - 1}>
            <PaginationLink onClick={nextPage}>Next</PaginationLink>
          </PaginationItem>

        </Pagination>
      </div>
    </>
  )

}

export default PlanTable
