import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import AlertModal from '../Shared/AlertModal'
import Loading from '../Shared/Loading'
import { useAuth } from '../../auth/authProvider'
import { useLoadUser } from '../../hooks/useUser'
import { setIsModal, setModalDetailMsg } from '../../redux/ducks/notification'
import { searchUser } from '../../utils/api/skadi'
import { wait } from '../../utils/helpers'

const SearchEmail = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { user: authUser } = useAuth()

  const [requestLoadUser, loadUserResponse] = useLoadUser()
  const { profile } = useSelector((state) => state.user)
  const {
    modalMessage: { modalDetail },
    apiMessage: { apiError },
    isModal
  } = useSelector((state) => state.notification)

  const [email, setEmail] = useState('')
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [showClearBtn, setShowClearBtn] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!isModal) {
      setIsSubmitted(false)
    }

    if (isSubmitted) {
      if (apiError && (loadUserResponse.state === 'failed' || !loadUserResponse.data.data.length)) {
        return dispatch([setIsModal(true), setModalDetailMsg(apiError)])
      }

      if (profile?.id) {
        return history.push(`/users/${profile.id}/profile`)
      }
    }
    return null
  }, [dispatch, profile, apiError, history, isSubmitted, isModal, loadUserResponse])

  const handleSubmit = async (e) => {
    e.preventDefault()
    // eslint-disable-next-line no-control-regex
    // eslint-disable-next-line no-useless-escape
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if (!emailRegex.test(email)) {
      return dispatch([setIsModal(true), setModalDetailMsg('Invalid Email Input')])
    }

    requestLoadUser(searchUser(authUser, { email }))

    setIsLoading(true)
    await wait(500)
    setIsLoading(false)
    return setIsSubmitted(true)
  }

  const handleChange = (e) => {
    setShowClearBtn(true)
    const values = e.target.value || ''
    setEmail(values)
  }

  const hanldeClearSearch = () => {
    setEmail('')
    setShowClearBtn(false)
  }

  return (
    <div className="search-box">
      <form className="search-form-container" onSubmit={handleSubmit}>
        <input value={email} onChange={handleChange} placeholder="Search user by email" />
        <div className="floating-label">Search user email</div>
        <FontAwesomeIcon
          className={`search-btn-clear ${showClearBtn ? 'visible' : ''}`}
          icon="times"
          onClick={hanldeClearSearch}
        />
      </form>

      {isLoading && <Loading />}

      <AlertModal modalMessage={modalDetail} />
    </div>
  )
}

export default SearchEmail
