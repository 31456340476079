import React, { useEffect, useState } from 'react'
import {
  Row, Col, Button, Alert, Badge
} from 'reactstrap'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import Loading from '../../../components/Shared/Loading'
import UserMenu from '../../../components/Menu/UserMenu'
import AlertModal from '../../../components/Shared/AlertModal'

import { useAuth } from '../../../auth/authProvider'
import { useLoadUser, useLoadUserSubscription, useCancelSubscription } from '../../../hooks/useUser'
import { resetUser } from '../../../redux/ducks/user'
import {
  resetAllMessages, setIsModal, setModalConfirmMsg
} from '../../../redux/ducks/notification'
import { getUser } from '../../../utils/api/skadi'
import { getLatestAccess, cancelSubscription } from '../../../utils/api/sigyn'
import { displayDateTime, capitalizeFirstLetter } from '../../../utils/format'
import { wait } from '../../../utils/helpers'

const Subscriptions = () => {
  const { user: authUser } = useAuth()
  const { id } = useParams()
  const dispatch = useDispatch()

  const [requestLoadUser] = useLoadUser()
  const [requestUserLoadSubscription] = useLoadUserSubscription()
  const [requestCancelSubscription, cancelSubscriptionResponse] = useCancelSubscription()

  const {
    user: { profile, subscription },
    userScope: { actions },
    notification: {
      apiMessage: { apiSuccess, apiError },
      modalMessage: { modalDetail, modalConfirm },
      isModal
    }
  } = useSelector((state) => state)

  const refundableStatuses = ['active', 'pending-cancellation', 'pending-plan-changed']
  const cancelableStatuses = ['active', 'pending-plan-changed']
  const refundableProviders = ['insider', 'braintree']

  const [isRefund, setIsRefund] = useState(false)

  useEffect(() => {
    dispatch([resetUser(), resetAllMessages()])
  }, [dispatch])

  useEffect(() => {
    requestLoadUser(getUser(authUser, id))
  }, [authUser, id, requestLoadUser])

  useEffect(() => {
    requestUserLoadSubscription(getLatestAccess(authUser, id))
  }, [authUser, id, requestUserLoadSubscription, cancelSubscriptionResponse])

  useEffect(() => {
    (async () => {
      if (apiSuccess) {
        await wait(2000)
        dispatch(resetAllMessages())
      }
    })()
  }, [dispatch, profile, apiSuccess])

  if (!profile) {
    return <Loading />
  }

  const handleCancelSubscription = (option = {}) => {
    if (option.refund) {
      setIsRefund(true)
    } else {
      setIsRefund(false)
    }
    dispatch([setIsModal(true), setModalConfirmMsg(option.refund ? 'Are you sure you want to refund and cancel the subscription immediately?' : 'Subscription will be canceled at the end of the billing cycle. Proceed?')])
  }

  const submitCancelSubscription = () => {
    dispatch([setIsModal(false), setModalConfirmMsg('')])
    requestCancelSubscription(cancelSubscription(authUser, subscription.id, { refund: isRefund }))
  }

  return (
    <Row className="detail-container">

      {!subscription && <Loading />}

      {cancelSubscriptionResponse.state === 'loading' && <Loading />}

      {isModal && modalConfirm && (
        <AlertModal
          submit={submitCancelSubscription}
          extraDetail={isRefund && <Badge color="warning">This action can not be undone!</Badge>}
        />
      )}

      <UserMenu />

      <Col lg={8} className="detail-generation">

        {apiSuccess && <Alert color="success">{apiSuccess}</Alert>}

        {!modalDetail && apiError && <Alert color="danger">{apiError}</Alert>}

        {subscription && !subscription.id && (
          <div>
            <p>This user does not have an active subscription or access.</p>
          </div>
        )}

        {subscription && subscription.id && (
          <>
            <div className="detail-el">
              <h3>Type</h3>
              <p>{subscription.type ? capitalizeFirstLetter(subscription?.type) : '(empty)'}</p>
            </div>

            <div className="detail-el">
              <h3>Provider</h3>
              <p>{subscription.provider ? capitalizeFirstLetter(subscription.provider) : '(empty)'}</p>
            </div>

            <div className="detail-el">
              <h3>Provider Id</h3>
              <p>{subscription.providerId || '(empty)'}</p>
            </div>

            <div className="detail-el">
              <h3>Plan</h3>
              <p>{subscription.plan || '(empty)'}</p>
            </div>

            <div className="detail-el">
              <h3>Status</h3>
              <p>{subscription.status ? capitalizeFirstLetter(subscription.status) : '(empty)'}</p>
            </div>

            <div className="detail-el">
              <h3>Auto Renew</h3>
              <p>{subscription.autoRenew ? capitalizeFirstLetter(subscription.autoRenew) : '(empty)'}</p>
            </div>

            <div className="detail-el">
              <h3>Origin</h3>
              <p style={{ width: '630px' }}>{subscription.origin || '(empty)'}</p>
            </div>

            <div className="detail-el">
              <h3>Starts At</h3>
              <p>{subscription.startsAt ? displayDateTime(new Date(subscription.startsAt)) : '(empty)'}</p>
            </div>

            <div className="detail-el">
              <h3>Ends At</h3>
              <p>{subscription.endsAt ? displayDateTime(new Date(subscription.endsAt)) : 'No Expiration Date'}</p>
            </div>

            <div className="detail-el">
              <h3>Updated At</h3>
              <p>{subscription.updatedAt ? displayDateTime(new Date(subscription.updatedAt)) : '(empty)'}</p>
            </div>

            <div className="detail-el">
              <h3>Created At</h3>
              <p>{subscription.createdAt ? displayDateTime(new Date(subscription.createdAt)) : '(empty)'}</p>
            </div>

            {actions.has('cancelSubscription')
              && refundableStatuses.includes(subscription.status)
              && refundableProviders.includes(subscription.provider)
              && subscription.type === 'subscription'
              && (

              <div className="btn-container btn-cancel-sub-container">

                { cancelableStatuses.includes(subscription.status) && (
                  <Button
                    color="warning"
                    size="sm"
                    type="button"
                    className="cancel-sub-btn"
                    onClick={handleCancelSubscription}
                  >
                    Cancel At End of Billing Cycle
                  </Button>
                )}

                <Button
                  color="danger"
                  size="sm"
                  type="button"
                  className="cancel-sub-btn"
                  onClick={() => handleCancelSubscription({ refund: true })}
                >
                  Refund and Cancel Immediately
                </Button>

              </div>

              )}

          </>
        )}
      </Col>
    </Row>
  )
}

export default Subscriptions
