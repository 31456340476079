import React, { useState, useEffect, useMemo } from 'react'
import {
  useTable, useFilters, useSortBy, usePagination
} from 'react-table'
import { useLocation, useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'
import Loading from '../Shared/Loading'

import { useAuth } from '../../auth/authProvider'
import { useListUsers } from '../../hooks/useUser'
import { listUsers } from '../../redux/ducks/user'
import { getAllUsersSort } from '../../utils/api/skadi'
import { displayDate } from '../../utils/format'

const UserTable = () => {
  const { user: authUser } = useAuth()
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const history = useHistory()

  const [requestListUsers, usersResponse] = useListUsers()
  const { users } = useSelector((state) => state.user)

  const [manualSortBy, setManualSortBy] = useState(false)

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        id: 'firstname',
        accessor: (row) => `${row.attributes.firstname || '(empty)'} ${row.attributes.lastname || '(empty)'}`
      },
      {
        Header: 'Email',
        id: 'email',
        accessor: 'attributes.email'
      },
      {
        Header: 'Type',
        id: 'type',
        accessor: 'attributes.type'
      },
      {
        Header: 'Verified At',
        id: 'emailVerifiedAt',
        accessor: 'attributes.emailVerifiedAt',
        Cell: ({ cell: { value } }) => (value ? displayDate(new Date(value)) : '')
      },
      {
        Header: 'Updated At',
        id: 'updatedAt',
        accessor: 'attributes.updatedAt',
        Cell: ({ cell: { value } }) => displayDate(new Date(value))
      }
    ],
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy }
  } = useTable(
    {
      columns,
      data: users,
      manualSortBy
    },
    useFilters,
    useSortBy,
    usePagination
  )

  useEffect(() => {
    dispatch(listUsers([]))
  }, [dispatch])

  useEffect(() => {
    if (pathname !== '/s/users') {
      setManualSortBy(true)
      let { id, desc } = sortBy[0] || []
      if (!id || id === 'type') {
        id = 'updatedAt'
        desc = true
      }

      const order = desc ? 'desc' : 'asc'
      const options = { order, field: id }
      requestListUsers(getAllUsersSort(authUser, options))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser, pathname, sortBy])

  const handleUser = (cell) => {
    const { id } = cell.row.original
    history.push(`/users/${id}/profile`)
  }

  return (
    <>
      {usersResponse.state === 'loading' && <Loading />}

      <div className="dashboard-head">
        <p className="page-show">Showing {users.length} records</p>

        {pathname !== '/s/users' && (
          <Button
            color="secondary"
            size="lg"
            type="submit"
            className="advanced-search-btn"
            onClick={() => history.push('/s/users')}
          >
            Advanced Search
          </Button>
        )}
      </div>

      <table className="table-container" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={
                    // eslint-disable-next-line no-nested-ternary
                    column.isSorted ? (column.isSortedDesc ? 'sort-desc' : 'sort-asc') : ''
                  }
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td onClick={() => handleUser(cell)} {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            )
          })}
        </tbody>
      </table>
    </>
  )
}

UserTable.defaultProps = {
  columns: [],
  data: [],
  cell: { value: '' }
}

UserTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.objectOf()),
  data: PropTypes.arrayOf(PropTypes.objectOf()),
  cell: PropTypes.shape({ value: PropTypes.string })
}

export default UserTable
