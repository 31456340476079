import React, { useState, useEffect, useMemo } from 'react'
import {
  useTable, useFilters, useSortBy, usePagination
} from 'react-table'
import { useLocation, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { Alert, Row, Col } from 'reactstrap'

import Loading from '../../../components/Shared/Loading'
import UserMenu from '../../../components/Menu/UserMenu'

import { useAuth } from '../../../auth/authProvider'
import useListRoles from '../../../hooks/useRole'
import { listRoles } from '../../../redux/ducks/role'
import {
  resetAllMessages
} from '../../../redux/ducks/notification'
import {
  getUser,
  getAllRolesSort,
  getUserRoles,
  removeUserRole,
  assignUserRole
} from '../../../utils/api/skadi'
import { wait } from '../../../utils/helpers'
import {
  useLoadUser,
  useListUserRoles,
  useAssignUserRole,
  useRemoveUserRole
} from '../../../hooks/useUser'
import { listUserRoles } from '../../../redux/ducks/user'

const RoleAssignment = () => {
  const { user: authUser } = useAuth()
  const { id: userId } = useParams()
  const dispatch = useDispatch()
  const { pathname } = useLocation()

  const [requestLoadUser] = useLoadUser()
  const [requestListRoles, rolesResponse] = useListRoles()
  const [requestListUserRoles] = useListUserRoles()

  const {
    rbac: { roles },
    notification: {
      apiMessage: { apiSuccess, apiError },
      modalMessage: { modalDetail }
    },
    user: { roles: userRoles }
  } = useSelector((state) => state)

  const [manualSortBy, setManualSortBy] = useState(false)

  useEffect(() => {
    dispatch([resetAllMessages()])
  }, [dispatch])

  useEffect(() => {
    requestLoadUser(getUser(authUser, userId))
  }, [authUser, userId, requestLoadUser])

  useEffect(() => {
    dispatch(listRoles([]))
  }, [dispatch])

  const columns = useMemo(
    () => {
      const CheckBox = (value) => {
        const [requestAssignUserRole] = useAssignUserRole()
        const [requestRemoveUserRole] = useRemoveUserRole(value)

        const isChecked = userRoles.some((role) => role.attributes.roleId === value)

        const updateUserRole = async (e) => {
          if (e.target.checked) {
            const roleId = e.target.value

            requestAssignUserRole(assignUserRole(authUser, userId, { roleId }))
          } else {
            const userRole = userRoles.find((role) => role.attributes.roleId === e.target.value)

            requestRemoveUserRole(removeUserRole(authUser, userRole.id))
          }
        }

        return <input type="checkbox" onChange={updateUserRole} defaultChecked={isChecked} value={value} />
      }

      return [
        {
          Header: 'Name',
          id: 'name',
          accessor: 'attributes.name'
        },
        {
          Header: 'Assigned Roles',
          id: 'assignedRoles',
          accessor: 'id',
          Cell: ({ value }) => CheckBox(value)
        }
      ]
    },
    [authUser, userId, userRoles]
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { sortBy }
  } = useTable(
    {
      columns,
      data: roles,
      manualSortBy,
      initialState: { pageIndex: 0, pageSize: 25 }
    },
    useFilters,
    useSortBy,
    usePagination
  )

  useEffect(() => {
    if (pathname !== '/s/roles') {
      setManualSortBy(true)
      const { id, desc } = sortBy[0] || []
      const order = desc ? 'desc' : 'asc'

      const options = { order, field: id }

      requestListRoles(getAllRolesSort(authUser, options))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser, pathname, sortBy])

  useEffect(() => {
    dispatch(listUserRoles([]))
  }, [dispatch])

  useEffect(() => {
    requestListUserRoles(getUserRoles(authUser, userId))
  }, [authUser, userId, requestListUserRoles])

  useEffect(() => {
    (async () => {
      if (apiSuccess) {
        await wait(2000)
        dispatch(resetAllMessages())
      }
    })()
  }, [apiSuccess, dispatch])
  return (
    <Row className="detail-container">
      {rolesResponse.state === 'loading'
      && (
        <div className="loading-overlay">
          <Loading />
        </div>
      )}

      <UserMenu />

      <Col lg={8} className="detail-generation">
        <table className="table-container role-table" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={
                    // eslint-disable-next-line no-nested-ternary
                    column.isSorted ? (column.isSortedDesc ? 'sort-desc' : 'sort-asc') : ''
                  }
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              )
            })}
          </tbody>
        </table>
        {apiSuccess && <Alert color="success">{apiSuccess}</Alert>}
        {!modalDetail && apiError && <Alert color="danger">{apiError}</Alert>}
      </Col>
    </Row>
  )

}

RoleAssignment.defaultProps = {
  columns: [],
  data: [],
  cell: { value: '' }
}

RoleAssignment.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.objectOf()),
  data: PropTypes.arrayOf(PropTypes.objectOf()),
  cell: PropTypes.shape({ value: PropTypes.string })
}

export default RoleAssignment
