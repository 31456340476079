import React from 'react'
import { Container, Row, Col } from 'reactstrap'

import Loading from '../components/Shared/Loading'
import { useAuth } from '../auth/authProvider'

const AuthAccount = () => {
  const { loading, user: authUser } = useAuth()

  if (loading || !authUser) {
    return <Loading />
  }

  return (
    <Container className="mb-5">
      <Row className="align-items-center profile-header mb-5 text-center text-md-left">
        <Col md>
          <h2>{authUser.first_name}</h2>
          <h2>{authUser.last_name}</h2>
          <p className="lead text-muted">{authUser.email}</p>
        </Col>
      </Row>
    </Container>
  )
}

export default AuthAccount
