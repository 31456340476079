import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Route, withRouter } from 'react-router-dom'
import { useAuth } from './authProvider'
import { useListAuthUserRoles, useListAuthUserPermissions } from '../hooks/useUserScope'
import { getUserRoles, getUserPermissions } from '../utils/api/skadi'
import getUserActions from '../utils/api/getUserActions'

const PrivateRoute = ({
  component: Component, path, scope: routeScope, ...rest
}) => {
  const {
    loading, isAuthenticated, loginWithRedirect, user
  } = useAuth()

  const [requestListUserRoles, userRolesResponse] = useListAuthUserRoles()
  const [requestListUserPermissions, userPermissionsResponse] = useListAuthUserPermissions()

  useEffect(() => {
    const getRolesAndPermissions = async () => {
      requestListUserRoles(getUserRoles(user, user['https://insider/memberId']))
      requestListUserPermissions(getUserPermissions(user, user['https://insider/memberId']))
    }
    getRolesAndPermissions()
    if (loading || isAuthenticated) {
      return
    }
    const fn = async () => {
      await loginWithRedirect({ appState: { targetUrl: path } })
    }
    fn()
  }, [loading, isAuthenticated, loginWithRedirect, path, user, requestListUserRoles,
    requestListUserPermissions])
  let isAuthorized = false
  let userActions = new Set()

  if (userRolesResponse.state === 'success' && userPermissionsResponse.state === 'success') {
    const userPermissions = userPermissionsResponse.data.data
    const userRoles = userRolesResponse.data.data
    userActions = getUserActions(userRoles, userPermissions)

    if (!routeScope.length) {
      isAuthorized = true
    } else {
      isAuthorized = routeScope.some((action) => userActions.has(action))
    }
  }

  const render = (props) => (isAuthenticated && isAuthorized
    ? <Component {...props} /> : null)

  return <Route path={path} render={render} {...rest} />
}

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
  scope: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string)]).isRequired
}

export default withRouter(PrivateRoute)
