import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Alert, Button } from 'reactstrap'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'

import GoBack from '../../components/Shared/GoBack'
import Loading from '../../components/Shared/Loading'
import Input from '../../components/Form/Input'

import { useAuth } from '../../auth/authProvider'
import { useAddEnterprise } from '../../hooks/useEnterprise'
import { resetAllMessages } from '../../redux/ducks/notification'
import { createEnterprise } from '../../utils/api/skadi'
import { setFormError } from '../../utils/format'

const CreateNewEnterprise = () => {
  const { user: authUser } = useAuth()
  const history = useHistory()
  const dispatch = useDispatch()
  const { register, handleSubmit, formState: { errors } } = useForm({ reValidateMode: 'onChange' })
  const [requestAddEnterprise, enterpriseResponse] = useAddEnterprise()

  const {
    enterprise: { enterprise: enterpriseData },
    notification: {
      apiMessage: { apiSuccess, apiError }
    }
  } = useSelector((state) => state)

  const [enterprise, setEnterprise] = useState({})

  useEffect(() => {
    dispatch(resetAllMessages())
  }, [dispatch])

  const onSubmit = async () => {
    const filteredEnterpriseFields = _.omit(enterprise, Object.keys(enterprise).filter((key) => enterprise[key].trim() === ''))

    if (filteredEnterpriseFields.domains) {
      filteredEnterpriseFields.domains = filteredEnterpriseFields.domains
        .split(/(\s+)/)
        .filter((field) => field.trim() !== '')
        .map((field) => field.trim())
    }

    requestAddEnterprise(createEnterprise(authUser, filteredEnterpriseFields))
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setEnterprise({
      ...enterprise,
      [name]: value
    })
  }

  return (
    <div className="create-form-container">
      {enterpriseResponse.state === 'loading' && <Loading />}

      <GoBack />

      {apiError && <Alert color="danger">{apiError}</Alert>}

      {apiSuccess && (
        <Alert color="success">
          Enterprise {enterprise.name} is created!{' '}
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <span className="user-go-btn" onClick={() => history.push(`/enterprises/${enterpriseData.id}`)}>
            Go to Enterprise
          </span>
        </Alert>
      )}

      <h2>New Enterprise</h2>

      <form className="form-container" onSubmit={handleSubmit(onSubmit)}>

        <Input
          className="form-input"
          label="Name"
          name="name"
          placeholder="Required"
          value={enterprise.name || ''}
          onChange={handleChange}
          ref={register({ required: true })}
        />

        { errors.name && <p className="form-input-error">{setFormError(errors.name)}</p>}

        <Input
          className="form-input"
          label="Domains"
          name="domains"
          placeholder="Optional (example: domain1 domain2)"
          value={enterprise.domains || ''}
          onChange={handleChange}
          ref={register({ pattern: /^[^,]+$/ })}
        />

        {errors.domains && <p className="form-input-error">{setFormError(errors.domains, 'enterprise')}</p>}

        <Input
          className="form-input form-type-number"
          label="Seats"
          name="seats"
          placeholder="Optional"
          type="number"
          value={enterprise.seats || ''}
          onChange={handleChange}
          ref={register({ min: 1 })}
        />

        {errors.seats && <p className="form-input-error">{setFormError(errors.seats, 'enterprise')}</p>}

        <Input
          className="form-input"
          label="Connection"
          name="connection"
          placeholder="Optional"
          value={enterprise.connection || ''}
          onChange={handleChange}
          ref={register}
        />

        <Input
          className="form-input"
          label="HubSpotId"
          name="hubSpotId"
          placeholder="Optional"
          value={enterprise.hubSpotId || ''}
          onChange={handleChange}
          ref={register}
        />

        <Button color="primary" size="lg" type="submit" className="form-btn-submit">
          Submit
        </Button>
      </form>
    </div>
  )
}

export default CreateNewEnterprise
