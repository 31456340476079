import React from 'react'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Nav, NavItem, Col } from 'reactstrap'
import { useAuth } from '../../auth/authProvider'

const UserMenu = () => {
  const { profile } = useSelector((state) => state.user)
  const { actions } = useSelector((state) => state.userScope)
  const { user: authUser } = useAuth()
  const { REACT_APP_ALLOWED_ENTERPRISES } = process.env

  const isInternalUser = profile.enterpriseId && REACT_APP_ALLOWED_ENTERPRISES.split(',').includes(profile.enterpriseId)

  return (
    <Col lg={3} className="side-menu-container">
      <Nav vertical>
        <NavItem className="menu-container side-menu">
          <NavLink
            exact
            to={`/users/${profile.id}/profile`}
            activeClassName="selected"
            className="menu-el"
          >
            Profile
          </NavLink>
        </NavItem>
        {(authUser['https://insider/memberId'] === profile.id || actions.has('listUserAccesses'))
        && (
          <NavItem className="menu-container side-menu">
            <NavLink
              exact
              to={`/users/${profile.id}/subscriptions`}
              activeClassName="selected"
              className="menu-el"
            >
              Subscriptions
            </NavLink>
          </NavItem>
        )}
        {actions.has('assignRoles') && isInternalUser
        && (
        <NavItem className="menu-container side-menu">
          <NavLink
            exact
            to={`/users/${profile.id}/roles`}
            activeClassName="selected"
            className="menu-el"
          >
            Roles
          </NavLink>
        </NavItem>
        )}
        {(actions.has('resetPassword') || actions.has('deleteUser'))
        && (
          <NavItem className="menu-container side-menu">
            <NavLink
              exact
              to={`/users/${profile.id}/settings`}
              activeClassName="selected"
              className="menu-el"
            >
              Account Settings
            </NavLink>
          </NavItem>
        )}
      </Nav>
    </Col>
  )
}

export default UserMenu
