import setHeaders from './setHeaders'

const {
  REACT_APP_SIGYN_ORIGIN: SIGYN_URL,
  REACT_APP_MEMBERSHIP_API_ORIGIN: MEMBERSHIP_API_URL
} = process.env

const getAccess = (user, id) => ({
  url: `${MEMBERSHIP_API_URL}/users/${id}/accesses?expand=subscription`,
  ...setHeaders(user)
})

const getLatestAccess = (user, id) => ({
  url: `${MEMBERSHIP_API_URL}/users/${id}/accesses?expand=subscription&listAll=true&limit=1`,
  ...setHeaders(user)
})

const getEnterpriseSubscription = (user, id) => ({
  url: `${MEMBERSHIP_API_URL}/enterprises/${id}/subscriptions`,
  ...setHeaders(user)
})

const getEnterpriseAccesses = (user, enterpriseId) => ({
  url: `${SIGYN_URL}/enterprises/${enterpriseId}/accesses`,
  ...setHeaders(user, { isInternal: true })
})

const updateEnterpriseAccess = (user, accessId, data) => ({
  url: `${SIGYN_URL}/accesses/${accessId}`,
  method: 'patch',
  data,
  ...setHeaders(user, { isInternal: true })
})

const createEnterpriseAccess = (user, enterpriseId, data) => ({
  url: `${SIGYN_URL}/enterprises/${enterpriseId}/accesses`,
  method: 'post',
  data,
  ...setHeaders(user)
})

const getAllStripePlans = (user) => ({
  url: `${SIGYN_URL}/plans?provider=stripe&limit=1000`,
  method: 'get',
  ...setHeaders(user)
})

const loadStripePlan = (user, planId) => ({
  url: `${SIGYN_URL}/plans/${planId}?include=prices`,
  method: 'get',
  ...setHeaders(user)
})

const createPlan = (user, data) => ({
  url: `${MEMBERSHIP_API_URL}/plans`,
  method: 'post',
  data,
  ...setHeaders(user)
})

const cancelSubscription = (user, subscriptionId, params) => ({
  url: `${SIGYN_URL}/subscriptions/${subscriptionId}/cancel`,
  method: 'patch',
  params,
  ...setHeaders(user, { isInternal: true })
})

export {
  getAccess,
  getEnterpriseSubscription,
  getEnterpriseAccesses,
  updateEnterpriseAccess,
  createEnterpriseAccess,
  getAllStripePlans,
  loadStripePlan,
  createPlan,
  getLatestAccess,
  cancelSubscription
}
