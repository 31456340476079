import React, { useEffect, useState } from 'react'

import { Breadcrumb, BreadcrumbItem } from 'reactstrap'
import { useSelector } from 'react-redux'

import style from './style.module.scss'

import Loading from '../../../components/Shared/Loading'
import PlanForm, { intervalMap } from '../../../components/Plan/Form/index'
import PlanPreview from '../../../components/Plan/Preview/index'
import PlanSuccess from '../../../components/Plan/Success/index'

import { useAuth } from '../../../auth/authProvider'
import { useCreatePlan } from '../../../hooks/usePlan'
import { createPlan } from '../../../utils/api/sigyn'
import { LOADING } from '../../../hooks/useApi'

const createPayload = (state) => {
  const payload = {
    name: state.planName,
    type: 'recurring',
    provider: state.planProvider,
    currency: state.planCurrency,
    interval: state.planInterval,
    validFor: Number(intervalMap[state.planInterval]),
    prices: []
  }
  if (state.adFree === 'true') {
    payload.metadata = { adFree: true }
  }

  if (!state.price2Amount) {
    payload.prices.push({ amount: state.price1Amount, type: 'standard', order: 1 })
  } else {
    payload.prices.push(
      { amount: state.price1Amount, type: 'intro', order: 1 },
      { amount: state.price2Amount, type: 'standard', order: 2 }
    )
  }
  return payload
}

const CreateNewPlan = () => {
  const { user: authUser } = useAuth()
  const [formData, setFormData] = useState(null)
  const [currentStep, setCurrentStep] = useState('form')
  const [requestCreatePlan, createPlanReponse] = useCreatePlan()

  const { planId } = useSelector((state) => state.plan)

  useEffect(() => {
    if (planId) setCurrentStep('success')
  }, [planId])

  const handleValidForm = (validForm) => {
    setFormData(validForm)
    setCurrentStep('preview')
  }

  const handleContinueEditing = () => {
    setCurrentStep('form')
  }

  const handleCreatePlan = () => {
    requestCreatePlan(createPlan(authUser, createPayload(formData)))
  }

  const bcCSSModuleOverride = {
    'breadcrumb-item': style.breadcrumbItem,
    active: style.breadcrumbItemActive
  }

  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem cssModule={bcCSSModuleOverride}><a href="/plans">All plans</a></BreadcrumbItem>

        {currentStep !== 'success'
          && <BreadcrumbItem cssModule={bcCSSModuleOverride} active={currentStep === 'form'}>Create a plan</BreadcrumbItem>}

        {currentStep === 'preview'
          && (
          <BreadcrumbItem cssModule={bcCSSModuleOverride} active={currentStep === 'preview'}>Preview plan</BreadcrumbItem>
          )}

        {currentStep === 'success'
            && (
              <>
                <BreadcrumbItem cssModule={bcCSSModuleOverride}>Create a new plan</BreadcrumbItem>
                <BreadcrumbItem cssModule={bcCSSModuleOverride} active={currentStep === 'success'}>Success: Plan created!</BreadcrumbItem>
              </>
            )}

      </Breadcrumb>

      {createPlanReponse.state === LOADING && <Loading />}

      { currentStep === 'form'
        && (
        <PlanForm
          formDataForEditing={formData}
          onValidFormSubmit={handleValidForm}
        />
        ) }

      { currentStep === 'preview'
        && (
        <PlanPreview
          dataToDisplay={formData}
          onContinueEditing={handleContinueEditing}
          onCreatePlan={handleCreatePlan}
        />
        )}

      { currentStep === 'success'
        && (
        <PlanSuccess
          dataToDisplay={formData}
        />
        )}

    </>
  )
}

export default CreateNewPlan
