import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import './index.scss'
import { datadogRum } from '@datadog/browser-rum'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { AuthProviderWithHistory } from './auth/authProvider'
import configureStore from './redux/store'

const { REACT_APP_NODE_ENV, REACT_APP_DD_APP_ID, REACT_APP_DD_CLIENT_TOKEN } = process.env

if (REACT_APP_NODE_ENV === 'production') {
  datadogRum.init({
    applicationId: REACT_APP_DD_APP_ID,
    clientToken: REACT_APP_DD_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'aegir',
    sampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input'
  })
  datadogRum.startSessionReplayRecording()
}

const store = configureStore()

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <AuthProviderWithHistory>
        <App />
      </AuthProviderWithHistory>
    </Router>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
