import React, {
  useState, useEffect, useContext, useMemo, useCallback
} from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import Cookies from 'js-cookie'
import { jwtDecode } from 'jwt-decode'

export const AuthContext = React.createContext()
export const useAuth = () => useContext(AuthContext)

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)

  const history = useHistory()

  useEffect(() => {
    const token = Cookies.get('X-Authorization') // custom token with roles, permissions
    if (!token) {
      setLoading(false)
      console.error('Retrieving Token Error: No Cookie X-Authorization found')
      history.push('/500')
      return
    }

    const decodedToken = jwtDecode(token)
    if (decodedToken.member_id) {
      decodedToken['https://insider/memberId'] = decodedToken.member_id
    } else {
      decodedToken['https://insider/memberId'] = decodedToken.sub
    }
    decodedToken['https://insider/token'] = token
    setUser(decodedToken)
    setIsAuthenticated(true)
    setLoading(false)
  }, [history])

  const loginWithRedirect = useCallback(async () => {
    history.push('/')
  }, [history])

  const logout = useCallback(() => {
    Cookies.remove('X-Authorization')
    setUser(null)
    setIsAuthenticated(false)
    history.push('/')
  }, [history])

  const contextValue = useMemo(() => ({
    isAuthenticated,
    user,
    loading,
    loginWithRedirect,
    logout
  }), [isAuthenticated, user, loading, loginWithRedirect, logout])

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  )
}

export const AuthProviderWithHistory = ({ children }) => {
  const history = useHistory()

  const onRedirectCallback = (appState) => {
    history.push(appState?.targetUrl || window.location.pathname)
  }

  return (
    <AuthProvider onRedirectCallback={onRedirectCallback} redirect_uri={window.location.origin}>
      {children}
    </AuthProvider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
}

AuthProviderWithHistory.propTypes = {
  children: PropTypes.node.isRequired
}
